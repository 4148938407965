<template>
  <div class="login-page">
    <!-- Branding / Logo Section -->
    <div class="branding">
      <nutrino-logo class="logo" />
      <div class="details">
        <h1>Join Baby Nutrino</h1>
        <p>Create your account and start your journey in baby care.</p>
      </div>
    </div>
    <!-- Signup Form Section -->
    <div class="login-section">
      <w-card class="login-card">
        <w-progress v-if="loading" class="progress" circle></w-progress>
        <w-form @submit="signup" v-model="valid" v-if="!loading" no-keyup-validation>
          <div class="login">
            <w-notification
                v-if="signupError"
                timeout="2000"
                shadow
                light
                no-border
                tile
                warning
                xl
                round
                :[position[0]]="true"
                :[position[1]]="true"
                @close="signupError = ''"
            >
              {{ signupError }}
            </w-notification>
            <w-input
                label="Email"
                type="email"
                v-model="email"
                autocomplete="username"
                required
                :validators="[validators.required]"
            ></w-input>
            <w-input label="Password"
                     :type="isPassword ? 'password' : 'text'"
                     :inner-icon-right="isPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
                     @click:inner-icon-right="isPassword = !isPassword"
                     :validators="[validators.required, validators.password]"
                     v-model="password" required></w-input>
            <w-button type="submit" class="btn">Signup</w-button>
          </div>
          <w-divider />
          <div class="social-login">
            <a href="/auth/google/signup">
              <w-button class="btn google">
                Signup with Google
              </w-button>
            </a>
            <!-- Optionally, include social signup buttons for Facebook/Apple -->
          </div>
          <p class="signup">
            Already have an account? <a href="/app/login">Login</a>
          </p>
        </w-form>
      </w-card>
      <!-- Footer Links -->
      <footer class="footer">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-use">Terms of Use</a>
        <a href="/contact">Contact Us</a>
      </footer>
    </div>
  </div>
</template>

<script setup>
import NutrinoLogo from "@/components/NutrinoLogo.vue";
import { useUserStore } from "@/stores/user.store";
import { ref, defineEmits, onMounted } from "vue";
import { storeToRefs } from "pinia";

const $emit = defineEmits(["navigateTo"]);
const valid = ref(false)
const email = ref("");
const password = ref("");
const signupError = ref("");
const position = ref(["top", "center"]);
const isPassword = ref(true);


const validators = {
  required: (value) => !!value || 'Required.',
  //password: (input) => /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,}).*$/.test(input) || 'Password must contain at least 8 characters, one uppercase letter, one number and one special character.'
  password: (input) => {
    let errors = [];
    if (!/(?=.*[A-Z])/.test(input)) {
      errors.push("one uppercase letter");
    }
    if (!/(?=.*\d)/.test(input)) {
      errors.push("one number");
    }
    if (!/(?=.*[!@#$%^&*])/.test(input)) {
      errors.push("one special character");
    }
    if (!/(?=.{8,})/.test(input)) {
      errors.push("8 characters long");
    }

    if (errors.length > 0) {
      return `Password is missing at least ${errors.join(", ")}.`;
    }
    return true;
  }
}

const userStore = useUserStore();
const { loading, isLoggedIn } = storeToRefs(userStore);

onMounted(async () => {
  console.log("Signup Page Mounted");
  if (isLoggedIn.value) {
    $emit("navigateTo", "/");
  } else {
    await userStore.load();
    if (isLoggedIn.value) {
      $emit("navigateTo", "/");
    }
  }
});

async function signup(event) {
  event.preventDefault()
  if(!valid.value) return
  signupError.value = ''
  const signupResponse = await userStore.signup(email.value,password.value);
  if(!signupResponse.created){
    signupError.value = signupResponse.message
  } else {
    $emit('navigateTo', 'login')
  }
}
</script>

<style scoped>
/* Import a stylish Google Font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

/* Mobile-first base styles with a soft, cream-inspired background */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  min-height: 100vh;
  background: linear-gradient(135deg, #fff1e6 0%, #fcebd9 100%);
  overflow-x: hidden;
}

/* Branding Section */
.branding {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #5c4d3d;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.logo {
  max-width: 120px;
  width: 100%;
  margin: 0 auto;
}

.details h1 {
  font-size: 1.8rem;
  margin: 0.5rem 0;
  font-weight: 600;
}

.details p {
  font-size: 1rem;
  margin: 0;
  opacity: 0.9;
}

/* Signup Section (same as login section) */
.login-section {
  width: 100%;
}

.login-card {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.login h2 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1.2rem;
  color: #333;
}

w-input,
w-button,
w-divider,
w-notification {
  margin-bottom: 1rem;
}

/* Buttons */
.btn {
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.btn.google {
  background-color: #db4437;
}

/* Social signup layout */
.social-login {
  margin-top: 1rem;
}

/* Login/Signup Link */
.signup {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #333;
}

.signup a {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
}

.signup a:hover {
  text-decoration: underline;
}

/* Footer */
.footer {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  color: #5c4d3d;
}

.footer a {
  margin: 0 0.5rem;
  color: #5c4d3d;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Larger screens: adjust layout and scale elements up */
@media (min-width: 768px) {
  .login-page {
    padding: 2rem;
  }
  .logo {
    max-width: 200px;
  }
  .details h1 {
    font-size: 2rem;
  }
  .details p {
    font-size: 1.2rem;
  }
  .login-section {
    width: 50%;
    margin: 0 auto;
  }
  .login-card {
    padding: 2rem;
  }
  .login h2 {
    font-size: 2rem;
  }
  .btn {
    font-size: 1.1rem;
    padding: 1rem;
  }
  .footer {
    font-size: 0.9rem;
    padding-top: 1rem;
  }
}
</style>
