<template>
  <div class="login-page">
    <!-- Branding / Logo Section -->
    <div class="branding">
      <nutrino-logo class="logo" />
      <div class="details">
        <h1>Welcome to Baby Nutrino</h1>
        <p>Your trusted partner in baby care.</p>
      </div>
    </div>
    <!-- Login Form Section -->
    <div class="login-section">
      <w-card class="login-card">
        <w-progress v-if="loading" class="progress" circle></w-progress>
        <w-form v-if="!loading" @submit="login">
          <div class="login">
            <w-notification
                v-if="loginError"
                timeout="2000"
                shadow
                light
                no-border
                tile
                warning
                xl
                round
                :[position[0]]="true"
                :[position[1]]="true"
                @close="loginError = ''"
            >
              {{ loginError }}
            </w-notification>
            <w-input
                label="Email"
                type="email"
                v-model="email"
                autocomplete="username"
                required
            ></w-input>
            <w-input
                label="Password"
                type="password"
                v-model="password"
                autocomplete="current-password"
                required
            ></w-input>
            <w-button type="submit" class="btn">Login</w-button>
          </div>
          <w-divider />
          <div class="social-login">
            <a href="/auth/google/login">
              <w-button class="btn google">
                Login with Google
              </w-button>
            </a>
<!--            <w-button class="btn facebook" @click="loginWithFacebook">-->
<!--              Login with Facebook-->
<!--            </w-button>-->
<!--            <w-button class="btn apple" @click="loginWithApple">-->
<!--              Login with Apple-->
<!--            </w-button>-->
          </div>
          <p class="signup">
            Don't have an account? <a href="/app/signup">Sign up</a>
          </p>
        </w-form>
      </w-card>
      <!-- Footer Links -->
      <footer class="footer">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-use">Terms of Use</a>
        <a href="/contact">Contact Us</a>
      </footer>
    </div>
  </div>
</template>

<script setup>
import NutrinoLogo from "@/components/NutrinoLogo.vue";
import { useUserStore } from "@/stores/user.store";
import {ref, defineEmits, onMounted} from "vue";
import { storeToRefs } from "pinia";

const $emit = defineEmits(["navigateTo"]);
const email = ref("");
const password = ref("");
const loginError = ref("");
const position = ref(["top", "center"]);

const userStore = useUserStore();
const { loading, isLoggedIn, load } = storeToRefs(userStore);

onMounted(async () => {
  console.log("Login Page Mounted");
  if (isLoggedIn) {
    $emit("navigateTo", "/");
  } else {
    await load();
    if (isLoggedIn) {
      $emit("navigateTo", "/");
    }
  }
});

async function login(event) {
  event.preventDefault();
  loginError.value = "";
  const loginResponse = await userStore.login(email.value, password.value);
  if (!loginResponse) {
    loginError.value = "Invalid email or password";
  } else {
    $emit("navigateTo", "/");
  }
}


// function loginWithFacebook() {
  // Handle Facebook login logic
// }

// function loginWithApple() {
  // Handle Apple login logic
// }
</script>

<style scoped>
/* Import a stylish Google Font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

/* Mobile-first base styles with a soft, cream-inspired background */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  min-height: 100vh;
  background: linear-gradient(135deg, #fff1e6 0%, #fcebd9 100%);
  overflow-x: hidden;
}

/* Branding Section */
.branding {
  text-align: center; /* Center the branding */
  margin-bottom: 1.5rem;
  color: #5c4d3d;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.logo {
  max-width: 120px;
  width: 100%;
  margin: 0 auto;
}

.details h1 {
  font-size: 1.8rem;
  margin: 0.5rem 0;
  font-weight: 600;
}

.details p {
  font-size: 1rem;
  margin: 0;
  opacity: 0.9;
}

/* Login Section */
.login-section {
  width: 100%;
}

.login-card {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Form Elements */
.login h2 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1.2rem;
  color: #333;
}

w-input,
w-button,
w-divider,
w-notification {
  margin-bottom: 1rem;
}

/* Buttons */
.btn {
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.btn.google {
  background-color: #db4437;
}

.btn.facebook {
  background-color: #3b5998;
}

.btn.apple {
  background-color: #333;
}

/* Social login layout */
.social-login {
  margin-top: 1rem;
}

/* Signup Link */
.signup {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #333;
}

.signup a {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
}

.signup a:hover {
  text-decoration: underline;
}

/* Footer */
.footer {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  color: #5c4d3d;
}

.footer a {
  margin: 0 0.5rem;
  color: #5c4d3d;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Larger screens: adjust layout and scale elements up */
@media (min-width: 768px) {
  .login-page {
    padding: 2rem;
  }
  /* Removed left alignment from branding to keep it centered */
  .logo {
    max-width: 200px;
  }
  .details h1 {
    font-size: 2rem;
  }
  .details p {
    font-size: 1.2rem;
  }
  .login-section {
    width: 50%;
    margin: 0 auto;
  }
  .login-card {
    padding: 2rem;
  }
  .login h2 {
    font-size: 2rem;
  }
  .btn {
    font-size: 1.1rem;
    padding: 1rem;
  }
  .footer {
    font-size: 0.9rem;
    padding-top: 1rem;
  }
}
</style>
